var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-data-list" },
    [
      _c(
        "el-button",
        { attrs: { icon: "el-icon-arrow-left" }, on: { click: _vm.goBack } },
        [_vm._v("返回")]
      ),
      _c(
        "div",
        { staticClass: "tab-wrapper" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "推荐的", name: "Recommends" } },
                [
                  _c("ul", { staticClass: "filter-list" }, [
                    _vm.filterCount > 0
                      ? _c(
                          "li",
                          {
                            class: _vm.status === "" ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.toogleStatus("")
                              },
                            },
                          },
                          [_vm._v("全部")]
                        )
                      : _vm._e(),
                    _vm.filterObj[32] > 0
                      ? _c(
                          "li",
                          {
                            class: _vm.status == "32" ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.toogleStatus("32")
                              },
                            },
                          },
                          [_vm._v("待筛选 " + _vm._s(_vm.filterObj[32]))]
                        )
                      : _vm._e(),
                    _vm.filterObj[1] > 0
                      ? _c(
                          "li",
                          {
                            class: _vm.status == "1" ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.toogleStatus("1")
                              },
                            },
                          },
                          [_vm._v("待反馈 " + _vm._s(_vm.filterObj[1]))]
                        )
                      : _vm._e(),
                    _vm.filterObj[2] > 0
                      ? _c(
                          "li",
                          {
                            class: _vm.status == "2" ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.toogleStatus("2")
                              },
                            },
                          },
                          [_vm._v("已接受 " + _vm._s(_vm.filterObj[2]))]
                        )
                      : _vm._e(),
                    _vm.filterObj[4] > 0
                      ? _c(
                          "li",
                          {
                            class: _vm.status == "4" ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.toogleStatus("4")
                              },
                            },
                          },
                          [_vm._v("面试 " + _vm._s(_vm.filterObj[4]))]
                        )
                      : _vm._e(),
                    _vm.filterObj[8] > 0
                      ? _c(
                          "li",
                          {
                            class: _vm.status == "8" ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.toogleStatus("8")
                              },
                            },
                          },
                          [_vm._v("offer/入职 " + _vm._s(_vm.filterObj[8]))]
                        )
                      : _vm._e(),
                    _vm.filterObj[16] > 0
                      ? _c(
                          "li",
                          {
                            class: _vm.status == "16" ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.toogleStatus("16")
                              },
                            },
                          },
                          [_vm._v("淘汰/拒绝 " + _vm._s(_vm.filterObj[16]))]
                        )
                      : _vm._e(),
                  ]),
                  _vm.total == 0 && !_vm.loading && _vm.candidateListFetched
                    ? _c("div", { staticClass: "empty" }, [
                        _c("span", { staticClass: "empty-img" }),
                        _c("p", { staticClass: "empty-data" }, [
                          _vm._v("暂无数据"),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "candidate-list",
                    },
                    _vm._l(_vm.candidateList, function (item, index) {
                      return _c(
                        "div",
                        { key: item.id + index, staticClass: "candidate-item" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "name",
                              attrs: {
                                href: `/#/candidateDetail/${item.candidateId}/recommendationList`,
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(item.realName))]
                          ),
                          _c(
                            "span",
                            { staticClass: "candidate-item-company" },
                            [
                              _c("i", { staticClass: "fa fa-black-tie" }),
                              _c("i", [_vm._v(_vm._s(item.company))]),
                              _vm._v(
                                "\n                             \n                            "
                              ),
                              _c("i", [_vm._v(_vm._s(item.title))]),
                            ]
                          ),
                          _c("span", { staticClass: "candidate-item-school" }, [
                            _c("i", { staticClass: "fa fa-trophy" }),
                            _c("i", [_vm._v(_vm._s(item.degreeInfo))]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.candidateList.length > 0 && _vm.total > _vm.page.pageSize
                    ? _c(
                        "div",
                        { staticClass: "pagination" },
                        [
                          _c("el-pagination", {
                            attrs: {
                              background: "",
                              "current-page": _vm.page.current,
                              "page-size": _vm.page.pageSize,
                              layout: "prev, pager, next, jumper",
                              total: _vm.total,
                            },
                            on: { "current-change": _vm.handleCurrentChange },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-right" },
            [
              _c(
                "el-dropdown",
                {
                  staticClass: "job-dropdown",
                  on: { command: _vm.handleJobSelect },
                },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v("\n                    " + _vm._s(_vm.selectedJob)),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "job-dropdown-popover",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown",
                    },
                    _vm._l(_vm.jobList, function (job) {
                      return _c(
                        "el-dropdown-item",
                        {
                          key: job.value,
                          class:
                            job.value == _vm.selectedJobValue ? "active" : "",
                          attrs: { command: job },
                        },
                        [_vm._v(_vm._s(job.text))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown",
                { on: { command: _vm.handleDateSelect } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v(
                      "\n                    " + _vm._s(_vm.dateRange.text)
                    ),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: 0 } }, [
                        _vm._v("近一个月"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: 1 } }, [
                        _vm._v("近三个月"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: 2 } }, [
                        _vm._v("全部"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }