<template>
    <div class="user-data-list">
        <el-button icon="el-icon-arrow-left" @click="goBack">返回</el-button>
        <div class="tab-wrapper">
            <el-tabs v-model="activeTab" type="card" @tab-click="handleClick">
                <el-tab-pane label="推荐的" name="Recommends">
                    <ul class="filter-list">
                        <li v-if="filterCount > 0" :class="status === '' ? 'active' : ''" @click="toogleStatus('')">全部</li>
                        <li v-if="filterObj[32] > 0" :class="status == '32' ? 'active' : ''" @click="toogleStatus('32')">待筛选 {{filterObj[32]}}</li>
                        <li v-if="filterObj[1] > 0" :class="status == '1' ? 'active' : ''" @click="toogleStatus('1')">待反馈 {{filterObj[1]}}</li>
                        <li v-if="filterObj[2] > 0" :class="status == '2' ? 'active' : ''" @click="toogleStatus('2')">已接受 {{filterObj[2]}}</li>
                        <li v-if="filterObj[4] > 0" :class="status == '4' ? 'active' : ''" @click="toogleStatus('4')">面试 {{filterObj[4]}}</li>
                        <li v-if="filterObj[8] > 0" :class="status == '8' ? 'active' : ''" @click="toogleStatus('8')">offer/入职 {{filterObj[8]}}</li>
                        <li v-if="filterObj[16] > 0" :class="status == '16' ? 'active' : ''" @click="toogleStatus('16')">淘汰/拒绝 {{filterObj[16]}}</li>
                    </ul>
                    <div class="empty" v-if="total == 0 && !loading && candidateListFetched">
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div>
                    <div class="candidate-list" v-loading="loading">
                        <div class="candidate-item" v-for="(item, index) in candidateList" :key="item.id + index">
                            <!-- <a class="name" :href="`/Headhunting/MyCompany.html#/candidateDetail/${item.candidateId}/recommendationList`" target="_blank">{{item.realName}}</a> -->
                            <a class="name" :href="`/#/candidateDetail/${item.candidateId}/recommendationList`" target="_blank">{{item.realName}}</a>
                            <span class="candidate-item-company">
                                <i class="fa fa-black-tie"></i>
                                <i>{{item.company}}</i>
                                &nbsp;
                                <i>{{item.title}}</i>
                            </span>
                            <span class="candidate-item-school">
                                <i class="fa fa-trophy"></i>
                                <i>{{item.degreeInfo}}</i>
                            </span>
                        </div>
                    </div>
                    <div class="pagination" v-if="candidateList.length > 0 && total > page.pageSize">
                        <el-pagination
                            background
                            @current-change="handleCurrentChange"
                            :current-page="page.current"
                            :page-size="page.pageSize"
                            layout="prev, pager, next, jumper"
                            :total="total">
                        </el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="tab-right">
                <el-dropdown @command="handleJobSelect" class="job-dropdown">
                    <span class="el-dropdown-link">
                        {{selectedJob}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="job-dropdown-popover">
                        <el-dropdown-item v-for="job in jobList" :key="job.value" :command="job" :class="job.value == selectedJobValue ? 'active' : ''">{{job.text}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown @command="handleDateSelect">
                    <span class="el-dropdown-link">
                        {{dateRange.text}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="0">近一个月</el-dropdown-item>
                        <el-dropdown-item :command="1">近三个月</el-dropdown-item>
                        <el-dropdown-item :command="2">全部</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
import { user as userUrl } from '#/js/config/api.json';
import moment from 'moment';
export default {
    data() {
        return {
            loading: false,
            sortList: ['按更新时间排序', '按发布时间排序', '按佣金金额排序', '按佣金比例排序'],
            sortType: 0,
            keyword: '',
            activeTab: 'Recommends',
            jobList: [{ text: '全部职位', value: '-1' }],
            selectedJob: '全部职位',
            selectedJobValue: '-1',
            dateRange: {
                type: 0,
                text: '近一个月'
            },
            page: {
                current: 1,
                pageSize: 10
            },
            total: 0,
            candidateList: [],
            filterCount: 0,
            filterObj: {},
            status: '',
            candidateListFetched: false,
        }
    },
    props: {
        userId: String,
    },
    mounted() {
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        init() {
            this.getJobList();
            this.getCandidateList();
        },
        changeSortType(type) {
            this.sortType = type;
        },
        handleJobSelect(job) {
            this.selectedJob = job.text;
            this.selectedJobValue = job.value;
            this.page.current = 1;
            this.getCandidateList();
        },
        handleDateSelect(type) {
            this.dateRange.type = type;
            this.dateRange.text = ['近一个月', '近三个月', '全部'][type];
            this.page.current = 1;
            this.getCandidateList();
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        getJobList() {
            _request({
                url: userUrl.get_hunting_jobs.replace('%p', this.userId),
                method: 'GET'
            }).then(res => {
                this.jobList = this.jobList.concat(res.huntingJobItems || []);
            })
        },
        selectJob(job) {
            this.job = job;
        },
        selectTimeLimit(timeLimit) {
            this.timeLimit = timeLimit;
        },

        getCandidateList() {
            this.loading = true;
            let dataJson = {
                recommendedByUserId: this.userId,
                type: 1,
                keywords: '',
                status: this.status,
                jobId: this.selectedJobValue == '-1' ? '' : this.selectedJobValue,
                startDate: '',
                endDate: '',
                start: (this.page.current - 1) * this.page.pageSize,
                take: this.page.pageSize
            }
            if(this.dateRange.type == 0) {
                dataJson.startDate = moment().subtract(30, 'days').format('YYYY/MM/DD') + ' 00:00:00';
                dataJson.endDate = moment().subtract(0, 'days').format('YYYY/MM/DD') + ' 23:59:59';
            }else if(this.dateRange.type == 1) {
                dataJson.startDate = moment().subtract(90, 'days').format('YYYY/MM/DD') + ' 00:00:00';
                dataJson.endDate = moment().subtract(0, 'days').format('YYYY/MM/DD') + ' 23:59:59';
            }
            _request({
                url: userUrl.get_recommend_list.replace('%p', this.userId),
                method: 'GET',
                data: dataJson
            }).then(res => {
                this.total = res.total;
                this.candidateList = res.list || [];
                let filterObj = {}, filterCount = 0;
                (res.recommendStatusStatistic || []).forEach(item => {
                    filterCount += item.count;
                    filterObj[item.name] = item.count;
                })
                this.filterCount = filterCount;
                this.filterObj = filterObj;
            }).finally(() => {
                this.candidateListFetched = true;
                this.loading = false;
            })
        },

        toogleStatus(status) {
            this.status = status;
            this.page.current = 1;
            this.getCandidateList();
        },

        handleCurrentChange(current) {
            this.page.current = current;
            this.getCandidateList();
        }
    }
}
</script>

<style lang="scss" scoped>
.user-data-list {
    .tab-wrapper {
        position: relative;
        margin-top: 25px;

        /deep/ .el-tabs {
            .el-tabs__item {
                min-width: 130px;
                text-align: center;
            }
        }

        .tab-right {
            position: absolute;
            top: -6px;
            right: 0;
            display: flex;

            .el-dropdown {
                flex-shrink: 0;
                line-height: 40px;
                margin-right: 12px;
                color: #b9b9b9;
            }

            .job-dropdown {
                margin-right: 50px;
            }

            .el-input-group__append .el-button {
                height: 100%;
            }
        }

        .filter-list {
            margin-top: 32px;
            li {
                display: inline-block;
                padding: 0 16px;
                height: 38px;
                line-height: 38px;
                margin: 0 16px 16px 0;
                color: #b9b9b9;
                background-color: #fff;
                border: 1px solid #d3d3d3;

                &.active {
                    color: $primary;
                    border-color: $primary;
                }
            }
        }

        .empty {
            text-align: center;

            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }

        .candidate-list .candidate-item {
            height: 80px;
            line-height: 80px;
            padding: 0 20px;
            border-bottom: 1px solid #ececec;

            &:last-child {
                border: 0 none;
            }

            &:hover {
                background-color: #f5fbfa;
            }

            .name {
                display: inline-block;
                font-size:16px;
                color: $primary;
                width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &-company, &-school {
                display: inline-block;
                max-width: 300px;
                color:#666;
                vertical-align: top;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &-company {
                padding-right: 30px;
            }
        }

    }
}
.job-dropdown-popover {
    max-height: 300px;
    overflow-y: scroll;

    .el-dropdown-menu__item {
        max-width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.active {
            color: $primary;
        }
    }
}
</style>